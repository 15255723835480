import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import Seo from "gatsby-plugin-wpgraphql-seo";
import Layout from "../components/layout";
import Share from "../components/share";

const BlogSingleStyles = styled.div`
  .container {
    padding: 4rem 3rem;
    box-sizing: border-box;
  }

  .pager {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2.5rem;
    font-size: 1.2rem;
    line-height: 1.4rem;

    ul {
      display: flex;
    }

    a {
      text-decoration: none;
      color: var(--dark);
      transition: color 0.3s ease;
      display: flex;
      align-items: center;

      &:hover {
        color: var(--blue);
      }
    }

    .gg-arrow-long-left {
      box-sizing: border-box;
      position: relative;
      display: inline-block;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      box-shadow: inset 0 0 0 1px;
      width: 12px;
      height: 3px;
      color: var(--blue);
      margin-right: 1rem;
    }

    .gg-arrow-long-left::after {
      content: "";
      display: block;
      box-sizing: border-box;
      position: absolute;
      width: 3px;
      height: 3px;
      border-bottom: 1px solid;
      border-left: 1px solid;
      transform: rotate(45deg);
      left: 0;
      bottom: -1px;
    }

    .gg-chevron-left {
      box-sizing: border-box;
      position: relative;
      display: inline-block;
      width: 11px;
      height: 11px;
      border: 1px solid transparent;
      border-radius: 100px;
      color: var(--blue);
      margin-right: 1rem;
    }

    .gg-chevron-left::after {
      content: "";
      display: inline-block;
      box-sizing: border-box;
      position: absolute;
      width: 5px;
      height: 5px;
      border-bottom: 1px solid;
      border-left: 1px solid;
      transform: rotate(45deg);
      left: 3px;
      top: 2px;
    }

    .gg-chevron-right {
      box-sizing: border-box;
      position: relative;
      display: inline-block;
      width: 11px;
      height: 11px;
      border: 1px solid transparent;
      border-radius: 100px;
      color: var(--blue);
      margin-left: 1rem;
    }

    .gg-chevron-right::after {
      content: "";
      display: block;
      box-sizing: border-box;
      position: absolute;
      width: 5px;
      height: 5px;
      border-bottom: 1px solid;
      border-right: 1px solid;
      transform: rotate(-45deg);
      right: 3px;
      top: 2px;
    }
  }

  .gatsby-image-wrapper {
    padding-bottom: 100%;
  }

  .post-title {
    font-size: 2.4rem;
    line-height: 2.4rem;
    margin: 4rem 0;
  }

  .meta {
    color: var(--p-grey);
    margin-bottom: 4rem;
  }

  .content-container {
    color: var(--p-grey);
    margin-bottom: 4rem;

    p,
    ul,
    ol,
    img,
    blockquote {
      font-size: 1.4rem;
      line-height: 2.1rem;
      margin-bottom: 3rem;
      margin-top: 0;
    }

    figure {
      margin: 0;
      padding: 0;
    }

    blockquote {
      font-style: italic;
      font-weight: 700;
      margin-left: 0;
      margin-right: 0;
      position: relative;
      padding-left: 9rem;

      p {
        margin-bottom: 1rem;
      }

      cite {
        font-weight: 400;
      }

      &:before {
        content: "“";
        display: block;
        z-index: 3;
        position: absolute;
        top: 63px;
        left: -24px;
        font-size: 180px;
        font-weight: 700;
        color: var(--grey);
        margin: 0;
        padding: 0;
      }
    }
  }

  .share {
    span {
      display: block;
      margin-bottom: 2rem;
    }

    .share-buttons {
      margin-top: -0.5rem;
      margin-left: -1.5rem;
    }

    svg {
      width: 45px;
      height: 45px;

      rect {
        fill: none;
      }

      path {
        fill: var(--dark-grey);
        transition: fill 0.3s ease;
      }

      &:hover {
        path {
          fill: var(--blue);
        }
      }
    }
  }

  @media screen and (min-width: 981px) {
    .blog-featured {
      .gatsby-image-wrapper {
        padding-bottom: 33.333%;
      }
    }

    .container {
      max-width: 1220px;
      padding-top: 6rem;
      padding-bottom: 6rem;
    }

    .pager {
      font-size: 1.6rem;

      .gg-chevron-left,
      .gg-chevron-right {
        &:after {
          top: 3px;
        }
      }
    }

    .post-title {
      font-size: 3rem;
      line-height: 4rem;
      margin-top: 3rem;
      margin-bottom: 2rem;
    }

    .meta {
      margin-bottom: 5rem;
    }

    .content-container {
      p,
      ul,
      ol,
      img,
      blockquote {
        font-size: 1.6rem;
        margin-bottom: 4rem;
      }
    }

    .share {
      font-size: 1.6rem;
    }
  }
`;

const BlogSingle = ({ data, pageContext }) => {
  console.log(pageContext);
  const options = { year: "numeric", month: "long", day: "numeric" };
  const date = new Date(data.wpgraphql.post.date);

  return (
    <Layout>
      <Seo post={data.wpgraphql.post} />

      <BlogSingleStyles>
        <div className="blog-featured desktop">
          {data.wpgraphql.post.featuredImage.node && (
            <Img
              fluid={data.wpgraphql.post.featuredImage.node.imageFile.childImageSharp.fluid}
              alt={data.wpgraphql.post.title}
            />
          )}
        </div>
        <div className="container">
          <div className="pager">
            <Link to="/blog" className="back">
              <i class="gg-arrow-long-left"></i> back to blogs
            </Link>
            <nav>
              <ul>
                {pageContext.previousPage ? (
                  <li>
                    <Link to={`/${pageContext.previousPage}/`} className="prev">
                      <i class="gg-chevron-left"></i> previous
                    </Link>
                  </li>
                ) : null}
                {pageContext.nextPage ? (
                  <li>
                    <Link to={`/${pageContext.nextPage}/`} className="next">
                      next <i class="gg-chevron-right"></i>
                    </Link>
                  </li>
                ) : null}
              </ul>
            </nav>
          </div>

          {data.wpgraphql.post.featuredImage.node && (
            <Img
              fluid={data.wpgraphql.post.featuredImage.node.imageFile.childImageSharp.fluid}
              alt={data.wpgraphql.post.title}
              className="mobile"
            />
          )}

          <h1
            className="post-title"
            dangerouslySetInnerHTML={{ __html: data.wpgraphql.post.title }}
          />

          <div className="meta">
            by {data.wpgraphql.post.author.node.name} | {date.toLocaleDateString("en-US", options)}{" "}
            | {data.wpgraphql.post.categories.edges[0].node.name}
          </div>

          <div
            className="content-container"
            dangerouslySetInnerHTML={{ __html: data.wpgraphql.post.content }}
          />

          <Share
            url={`${process.env.SITE_URL}/${data.wpgraphql.post.slug}`}
            title={data.wpgraphql.post.title}
            media={`${data.wpgraphql.post.featuredImage.node.mediaItemUrl}`}
          />
        </div>
      </BlogSingleStyles>
    </Layout>
  );
};

export default BlogSingle;

export const query = graphql`
  query($databaseId: ID!) {
    wpgraphql {
      post(id: $databaseId, idType: DATABASE_ID) {
        slug
        title
        date
        content(format: RENDERED)
        categories {
          edges {
            node {
              name
            }
          }
        }
        excerpt(format: RENDERED)
        featuredImage {
          node {
            altText
            title(format: RENDERED)
            mediaItemUrl
            slug
            sourceUrl
            mediaItemId
            modified
            imageFile {
              childImageSharp {
                fluid {
                  base64
                  tracedSVG
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
              }
            }
          }
        }
        author {
          node {
            name
          }
        }
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
      }
    }
  }
`;
