import React from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  PinterestIcon,
  PinterestShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";

const Share = ({ url, title, media }) => {
  console.log(url, title, media);
  return (
    <div className="share">
      <span>Share this post</span>
      <div className="share-buttons">
        <FacebookShareButton url={url} title={title}>
          <FacebookIcon size={40} />
        </FacebookShareButton>
        <TwitterShareButton url={url} title={title}>
          <TwitterIcon size={40} />
        </TwitterShareButton>
        <LinkedinShareButton url={url} title={title}>
          <LinkedinIcon size={40} />
        </LinkedinShareButton>
        <PinterestShareButton url={url} title={title} media={media}>
          <PinterestIcon size={40} />
        </PinterestShareButton>
      </div>
    </div>
  );
};

export default Share;
